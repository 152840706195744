module.exports = [{
      plugin: require('/Users/joemidi/Sites/jellyfish-creative-display-gallery/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/joemidi/Sites/jellyfish-creative-display-gallery/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/joemidi/Sites/jellyfish-creative-display-gallery/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
